<template>
    <ul>
        <component
            :is="resolveNavItemComponent(item)"
            v-for="item in items"
            :isRoled="isRoled(item)"
            :key="item.header || item.title"
            :item="item"
        />
    </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import { getUserData } from "@/app/admin/auth/utils";
import jwtDecode from "jwt-decode";
import { All } from "@/router";

export default {
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        VerticalNavMenuGroup
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    setup() {
        provide("openGroups", ref([]));

        return {
            resolveNavItemComponent
        };
    },
    methods: {
        isRoled(item) {
            // item
            // return true

            return (
                item.roles.includes(All)||
                item.roles.indexOf(
                    jwtDecode(getUserData().accessToken)[
                        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                    ]
                ) != -1 
            );
        }
    }
};
</script>

import { Accountant, Admin, SuperAdmin, DataEntry, Teacher } from "@/router";
export default [
    {
        title: "الرئيسية",
        route: "/admin/home",
        icon: "home-alt",
        roles: [SuperAdmin, Admin],
    },
    {
        header: "ادارة المحتوى",
        roles: [SuperAdmin, Admin, DataEntry],
    },
    {
        title: "الجامعات /مراحل التعليم",
        route: "/admin/university",
        icon: "university",
        roles: [SuperAdmin, Admin],
    },
    {
        title: "الكليات /الصفوف",
        route: "/admin/faculty",
        icon: "store-alt",
        roles: [SuperAdmin, Admin],
    },
    {
        title: "الفروع",
        route: "/admin/branches",
        icon: "store",
        roles: [SuperAdmin, Admin],
    },
    {
        title: "المواد",
        route: "/admin/subjects",
        icon: "books",
        roles: [SuperAdmin, Admin, DataEntry],
    },
    {
        title: "المحتوى التعليمي",
        icon: "folder",
        roles: [SuperAdmin, Admin, DataEntry],
        children: [
            {
                title: "الكورسات",
                route: "/admin/courses",

                roles: [SuperAdmin, Admin, DataEntry, Teacher],
            },
            {
                title: "الجلسات",
                route: "/admin/sessions",
                roles: [SuperAdmin, Admin, DataEntry, Teacher],
            },
            {
                title: "كويزات الجلسات ",
                route: "/admin/quiz",
                icon: "question-circle",
                roles: [SuperAdmin, Admin, DataEntry],
            },
        ],
    },
    {
        title: "محتوى الاختبارات",
        icon: "folder",
        roles: [SuperAdmin, Admin, DataEntry],
        children: [
            {
                title: "الوحدات",
                route: "/admin/unit",

                roles: [SuperAdmin, Admin, DataEntry],
            },
            {
                title: "الاقسام الاساسية",
                route: "/admin/chapter",

                roles: [SuperAdmin, Admin, DataEntry],
            },

            {
                title: "الدروس",
                route: "/admin/lessons",

                roles: [SuperAdmin, Admin, DataEntry],
            },
            {
                title: "بنوك الاسئلة",

                route: "/admin/courseQuestions",
                icon: "file-plus",
                roles: [SuperAdmin, Admin, DataEntry],
            },
            {
                title: " الدورات ",
                route: "/admin/previousExams",
                icon: "moneybag",
                roles: [SuperAdmin, Admin, DataEntry],
            },
        ],
    },

   
    // {
    //     title: "الاختبارات ",
    //     route: "/admin/courseQuestion",
    //     icon: "file-copy-alt",
    //     roles: [SuperAdmin, Admin, DataEntry],
    // },

    {
        title: "الاشعارات",
        route: "/admin/notifications",
        icon: "bell",
        roles: [SuperAdmin, Admin],
    },
    {
        title: "الاعلانات",
        route: "/admin/adverts",
        icon: "megaphone",
        roles: [SuperAdmin, Admin],
    },
   
    {
        title: " طلبات التواصل",
        route: "/admin/contactUs",
        icon: "envelope-download",
        roles: [SuperAdmin, Admin],
    },
    {
        header: "المالية والارباح",
        roles: [SuperAdmin, Admin],
    },
    {
        title: "رموز التفعيل",
        route: "/admin/codes",
        icon: "qrcode-scan",
        roles: [SuperAdmin, Admin],
    },
    {
        title: " كشف حساب نقطة البيع",
        icon: "bill",
        route: "/admin/invoiceSellPoint",
        roles: [Accountant, SuperAdmin, Admin],
    },
    {
        header: "الحسابات",
        roles: [SuperAdmin, Admin],
    },
    {
        title: "مستخدمو اللوحة",
        route: "/admin/dashboardUsers",
        icon: "user-circle",
        roles: [SuperAdmin],
    },
    {
        title: "مستخدم التطبيق",
        route: "/admin/students",
        icon: "users-alt",
        roles: [SuperAdmin, Admin],
    },
    {
        title: " نقاط البيع",
        route: "/admin/sellpoints",
        icon: "money-withdraw",
        roles: [SuperAdmin, Admin],
    },

    {
        header: "الاعدادات",
        roles: [SuperAdmin, Admin],
    },

    {
        title: "الإعدادات العامة",
        route: "/admin/settings",
        icon: "moneybag",
        roles: [SuperAdmin, Admin],
    },
];

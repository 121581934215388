import { useUtils as useAclUtils } from "@core/libs/acl";
// import { getUserData } from "@/auth/utils";

const { canViewVerticalNavMenuHeader } = useAclUtils();
import { getUserData } from "@/app/admin/auth/utils";
import jwtDecode from "jwt-decode";
import { All } from "@/router";
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    render(h) {
        const span = h("span", {}, this.item.header);
        const icon = h("feather-icon", {
            props: { icon: "MoreHorizontalIcon", size: "18" }
        });
        if (
            canViewVerticalNavMenuHeader(this.item) &&
            this.item.roles.indexOf(
                jwtDecode(getUserData().accessToken)[
                    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                ]
            ) != -1|| this.item.roles.includes(All)
        ) {
            // if (canViewVerticalNavMenuHeader(this.item)) {
            return h("li", { class: "navigation-header text-truncate" }, [
                span,
                icon
            ]);
        }
        return h();
    }
};
